@import '../vars-mixins';

.preview_wrapper{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 10000;
}
.preview_trigger{
    z-index: 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.preview{
    position: absolute;
    top: 50%;
    left: 50%;
    overflow: hidden;
    width: 800px;
    height: 500px;
    border-radius: 16px;
    z-index: 0;
    transform: translate(-50%, -50%);
    @include r('xxl') {
        border-radius: 12px;
    }
    @include r('lg') {
        height: auto;
    }
    @include r('sm') {
        width: calc(100% - 30px);
    }

    &__list {
        position: absolute;
        top: 15px;
        left: 15px;
        @include r('sm') {
            display: none;
        }
    }
    &__item {
        display: flex;
        align-items: center;
        max-width: 240px;
        padding: 12px;
        font-size: 14px;
        line-height: 110%;
        letter-spacing: 0.2px;
        color: #262628;
        @include r('xxl') {
            padding: 10px;
            font-size: 12px;
        }
        svg {
            display: block;
            width: 30px;
            height: 30px;
            margin-right: 12px;
        }
    }
    canvas {
        @include r('sm') {
            width: 100%!important;
            height: auto!important;
        }
    }
}
