@import '../vars-mixins';

button {
    background: none;
    outline: 0 !important;
}

.canvas-container{
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: scale(0.27) translate(-185%, -188%);
    @include r('lg') {
        transform: scale(0.15) translate(-328%, -338%);
    }
    @include r('md') {
        transform: scale(0.2) translate(-249%, -284%);
    }
    @include r('sm') {
        transform: scale(0.15) translate(-333%, -353%);
    }
}
.calc_canvas{
    display: none;
}
.editor-container{
    max-width: 1920px;
    height: 820px;
    margin: 0 auto;
    position: relative;
    border-top: 1px solid #DDDEE0;
    border-bottom: 1px solid #DDDEE0;
    box-shadow: 32px 32px 60px rgba(228, 216, 216, 0.5);
    overflow: hidden;
    @include r('xl') {
        height: 700px;
    }
    @include r('md') {
        height: 900px;
    }
    @include r('sm') {
        height: 650px;
    }
}
.editor-container > .container-fluid{
    height: 100%;
}
.editor-box {
    height: 100%;
    @include r('md') {
        flex-flow: column wrap;
    }
}
.editor-wrapper{
    position: relative;
    width: 100%;
    height: 600px;
    @include r('xl') {
        height: 500px;
    }
    @include r('md') {
        height: 657px;
    }
    @include r('sm') {
        height: 530px;
    }
}
.editor-column{
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
    position: relative;
    z-index: 2;
    padding: 0 30px;
    border-right: 1px solid #C4C4C4;
    @include r('lg') {
        padding: 0 15px;
    }
    @include r('md') {
        flex: auto;
        width: 100%;
        max-width: none;
        order: 2;
        position: absolute;
        top: 185px;
        height: 700px;
        background: #fff;
        z-index: 1;
    }
    @include r('sm') {
        top: 113px;
        height: 536px;
    }
}
.options-column{
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
    position: relative;
    height: 800px;
    padding: 77px 0 144px;
    overflow: hidden;
    @include r('xxl') {
        padding: 93px 0 144px;
    }
    @include r('xl') {
        height: 700px;
        padding: 90px 0 144px;
    }
    @include r('md') {
        flex: inherit;
        max-width: none;
        height: 896px;
        order: 1;
        z-index: 2;
        display: flex;
        flex-flow: column;
        padding: 0;
    }
    @include r('sm') {
        height: 648px;
    }
    &_hide {
        height: auto;
        & .options-box {
            @include r('md') {
                height: auto;
                overflow-y: scroll;
            }
        }

        & .show-btn {
            display: none;
            @include r('md') {
                display: flex;
            }
        }
    }
}
.options-box {
    height: 580px;
    overflow-y: scroll;
    // @include r('xl') {
    //     height: 4px;
    //     margin-top: 63px;
    //     margin-bottom: 85px;
    // }
    @include r('xl') {
        height: 464px;
    }
    @include r('md') {
        height: auto;
        order: 2;
        overflow-y: hidden;
    }
}
// .options-box::-webkit-scrollbar {
//     width: 11px;
// }

// .options-box::-webkit-scrollbar-track {
//     background: transparent;
// }

// .options-box::-webkit-scrollbar-thumb {
//     background-color: #f3f0dd;
//     border-radius: 6px;
//     border: 1px solid #154734;
// }

.history{
    position: absolute;
    top: 10px;
    right: 20px;
    z-index: 100;

    @include r('xl') {
        right: 10px;
    }
    @include r('md') {
        right: 50px;
    }
    @include r('sm') {
        right: 50%;
        transform: translateX(50%);
    }
}
.history-buttons{
    background: #FFFFFF;
    border: 1px solid #DDDEE0;
    box-sizing: border-box;
    border-radius: 12px;
    width: 60px;
    height: 60px;
    margin: 0 10px;
    transition: 0.3s all ease-in-out;

    @include r('xl') {
        width: 40px;
        height: 40px;
        margin: 0 5px;
    }

    svg * {
        transition: 0.3s all ease-in-out;
    }
}
.history-buttons:hover{
    background: #262628;
    box-shadow: rgb(17 17 17 / 20%) 4px 8px 12px;
}
.history-buttons:disabled {
    border: 1px solid #DDDEE0;
}
.history-buttons:disabled svg {
    opacity: 0.5;
}
.history-buttons:hover svg *{
    fill: #fff;
}
.history-buttons svg{
    @include r('xl') {
        width: 15px;
    }
}
.grid-text{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 9px;
}
.grid-switcher{
    background: #FFFFFF;
    padding: 12px;
    border: 1px solid #F6F7F7;
    box-sizing: border-box;
    box-shadow: 32px 32px 62px rgba(228, 216, 216, 0.5);
    border-radius: 12px;
    @include r('sm') {
        padding: 0;
        border: none;
    }
}
.grid-switcher-right{
    float: right;
    display: flex;
    align-items: center;
    justify-content: center;
}
.grid-switcher-right label{
    margin-left: 15px;
    font-weight: 500;
    font-size: 12px;
    line-height: 125%;
    letter-spacing: 0.03em;
    color: #262628;

    span {
        display: inline-block;
    }

    @include r('xl') {
        font-size: 10px;
    }
    @include r('sm') {
        display: none;
    }
}
.info-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 22px;
    background: #FFFFFF;
    border: 1px solid #F6F7F7;
    box-sizing: border-box;
    box-shadow: 32px 32px 62px rgba(228, 216, 216, 0.5);
    border-radius: 12px;

    @include r('xl') {
        padding: 15px 12px;
    }
    @include r('sm') {
        padding: 0;
        box-shadow: none;
    }
}
.info-button-text {
    display: block;
    margin-left: 10px;
    text-align: left;
    font-weight: 500;
    font-size: 12px;
    line-height: 125%;
    letter-spacing: 0.03em;
    color: #262628;

    @include r('xl') {
        font-size: 10px;
    }
    @include r('sm') {
        display: none;
    }
}

.react-switch-bg{
    background: #FFFFFF!important;
    border: 1px solid #DDDEE0;
    box-sizing: border-box;
    border-radius: 36px;
}

.show-preview {
    position: absolute;
    right: auto;
    bottom: 160px;
    left: 50%;
    transform: translateX(-50%);
    background: #FFA400;
    border-radius: 16px;
    border: 0;
    padding: 10px 40px;
    box-shadow: 4px 8px 20px rgba(17, 17, 17, 0.2);
    transition: 0.3s all ease-in;
    z-index: 10;
    @include r('xl') {
        bottom: 158px;
        padding: 15px 40px;
        border-radius: 12px;
    }
    @include r('lg') {
        padding: 16px 20px;
    }
    @include r('md') {
        right: auto;
        bottom: 158px;
        left: 50%;
        transform: translateX(-50%);
        padding: 16px 40px;
    }
    @include r('sm') {
        top: 10px;
        right: 15px;
        bottom: auto;
        left: auto;
        transform: none;
        padding: 14px;
        box-shadow: none;
    }

    svg {
        @include r('xl') {
            height: 30px;
        }
        @include r('sm') {
            width: 42px;
            height: 22px;
            border-radius: 8px;
        }
    }
}
.show-preview:hover {
    background: #262628;
}
.editor-network-box {
    display: flex;
    justify-content: flex-end;
}
.editor-network {
    max-width: 250px;
}
.editor-bottom-box {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0 30px;
    @include r('xl') {
        padding: 0 15px;
    }
    @include r('md') {
        padding: 0 45px;
    }
    @include r('sm') {
        padding: 0;
    }
}
.editor-bottom{
    position: relative;
    background: #FFFFFF;
    border: 1px solid rgba(221, 222, 224, 0.25);
    border-bottom: none;
    box-sizing: border-box;
    border-top-right-radius: 24px;
    border-top-left-radius: 24px;
    padding: 18px 0;
    margin-top: 15px;
    @include r('lg') {
         padding: 14px 0;
     }
    /*@include r('xxl') {
        padding: 15px 2vw;
    }*/
    @include r('sm') {
        padding: 0 15px;
        border: none;
    }
}
.editor-bottom .row {
    justify-content: center;
}
.editor-bottom > .row{
    @include r('sm') {
        flex-flow: column;
    }
}
.editor-btns {
    display: flex;
    justify-content: center;
    width: 64.2%;
    padding: 0 16px;
    @include r('sm') {
        width: auto;
        padding: 0 3px;
    }
    @include r('s') {
        padding: 0 16px;
    }
}
.editor-btns div {
    width: 44%;
    height: 100%;
}
.editor-btns div:not(:first-child) {
    margin-left: 16px;
}
.editor-btns .object-buttons{
    @include r('sm') {
        height: 50px;
        font-size: 12px;
    }
}
.editor-download {
    width: 35.8%;
    padding: 0 16px;
    /*@include r('xl') {
        padding: 0;
    }
    @include r('xxl') {
        padding: 0;
    }*/
    @include r('sm') {
        display: flex;
        width: auto;
        flex: 0 0 100%;
        max-width: 100%;
        padding: 10px 16px
    }
}
.object-buttons{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #DDDEE0;
    box-sizing: border-box;
    border-radius: 12px;
    background: #FFFFFF;
    padding: 0 15px;
    position: relative;
    width: 100%;
    height: 105px;
    text-align: center;
    transition: 0.3s all ease-in-out;
    color: #262628;
    /*@include r('xxl') {
        padding: 20px;
    }
    @include r('xl') {
        padding: 15px;
    }*/
}
.object-buttons:hover{
    background: #262628;
    color: #fff;
}
.object-buttons svg{
    display: initial;
    transform: scale(0.8);
    margin-right: 15px;
    transition: 0.3s all ease-in-out;
    @include r('xl') {
        margin-right: 10px;
    }
    @include r('lg') {
        display: none;
    }
    @include r('md') {
        display: initial;
    }
}
.object-buttons svg *{
    transition: 0.3s all ease-in;
}
.object-buttons:hover svg *{
    stroke: #ffffff;
}
.object-buttons span{
    font-size: 14px;
    line-height: 150%;

    @include r('xl') {
        font-size: 12px;
    }
    @include r('sm') {
        font-size: 10px;
    }
}
.editor-function{
    @include r('sm') {
        padding: 0 15px;
    }
}

.editor-function .row .col-4{
    @include r('lg') {
        display: flex;
        align-items: flex-end;
    }
}

.editor-function .row .col-4:nth-child(2){
    @include r('sm') {

    }
}
.download{
    background: #262628;
    border-radius: 16px;
    border: 0;
    color: #FFFFFF;
    display: block;
    width: 100%;
    max-width: 294px;
    padding: 20px;
    margin: 0 auto 10px;
    font-size: 14px;
    transition: 0.3s all ease-in-out;

    /*@include r('xl') {
        padding: 15px;
        border-radius: 12px;
        font-size: 10px;
    }*/
    @include r('sm') {
        max-width: 49%;
        margin: 0 10px 0 0;
        font-size: 10px;
    }
}
.download:hover {
    background: #FFA400;
}
.check-box-row label{
    font-size: 12px;

    @include r('xl') {
        font-size: 10px;
    }
}
.rc-checkbox{
    border: 5px solid#F3F4F6;
    border-radius: 50%;
}
.rc-checkbox-inner{
    background: #262628;
    border-radius: 50%;
    transition: none;
}
.rc-checkbox-checked .rc-checkbox-inner{
    background: #262628;
    border-radius: 50%;
}
.rc-checkbox-inner:after{
    display: none;
    animation-name: none;
}
.rc-checkbox-checked .rc-checkbox-inner:after{
    display: block;
    animation-name: none;
}
.price_wrapper{
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 30;
    display: flex;
    align-items: center;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 20px -20px 62px rgba(87, 87, 87, 0.1);
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    padding: 36px 40px;
    @include r('lg') {
        padding: 36px 15px;
    }
    @include r('md') {
        order: 1;
        position: static;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom: 1px solid #DDDEE0;
        padding: 20px 45px;
    }

    @include r('sm') {
        padding: 5px 15px;
    }
}
.price_wrapper .row {
    width: 100%;
    margin: 0;
}
.price_wrapper p{
    margin-bottom: 0;
    font-size: 24px;
    text-align: center;
    font-weight: bold;
    color: #FFA400;

    @include r('xl') {
        font-size: 18px;
    }

    @include r('sm') {
        font-size: 14px;
        font-weight: 500;
        text-align: left;
    }
}
.price_wrapper p span{
    color: #000;
}
.price-box {
    display: flex;
    align-items: center;
    justify-content: center;

    @include r('md') {
        justify-content: flex-start;
        padding: 0;
    }
}
.order-btn-box {
    padding: 0;
}
.templates{
    position: absolute;
    top: 0;
    left: 10px;
    z-index: 100;
    display: flex;
    flex-flow: column;
    align-items: center;
    height: 520px;
    overflow: hidden;
    @include r('xl') {
        height: 420px;
    }
    @include r('lg') {
        height: 375px;
    }
    @include r('md') {
        left: 35px;
    }
    @include r('sm') {
        left: 0;
        height: 330px;
    }
}

.templates-inner {
    padding: 10px 20px 20px;

    @include r('xl') {
        padding: 10px;
    }
}

.templates-title{
    width: 80px;
    padding-bottom: 10px;
    margin: 0 auto;
    font-size: 14px;
    text-align: center;
    @include r('xl') {
        font-size: 10px;
        width: 60px;
    }
    @include r('sm') {
        display: none;
    }
}
.template{
    position: relative;
    width: 100px;
    height: 100px;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #DDDEE0;
    border-radius: 12px;
    background: #FFFFFF;
    box-shadow: 0 8px 35px rgba(17, 17, 17, 0.1);
    overflow: hidden;
    transition: 0.3s all ease-in;
    @include r('xl') {
        width: 75px;
        height: 75px;
        padding: 5px;
    }
    @include r('lg') {
        width: 60px;
        height: 60px;
        margin-bottom: 5px;
    }
    @include r('sm') {
        width: 45px;
        height: 45px;
    }
}
.template:hover {
    box-shadow: rgb(17 17 17 / 20%) 4px 8px 12px!important;
}
.template img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.template button{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 0;
    background: transparent;
    width: 100%;
}
.template p {
    position: absolute;
    width: 100%;
    bottom: 3px;
    left: 50%;
    opacity: 0;
    transform: translateX(-50%);
    transition: 0.3s opacity ease;
    text-align: center;
    pointer-events: none;
    font-size: 12px;

    @include r('xxl') {
        font-size: 10px;
    }

    @include r('xl') {
        font-size: 8px;
    }
}
.template:hover p {
    opacity: 1;
}
.editor-overlay{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #FFFFFF;
    z-index: 101;
}
#png{
    /* width: 1200px;
    height: 800px;
    position: fixed;
    top: 0;
    left: 0;
    transform: scale(0.5);
    z-index: 1000; */
    display: none;
}
.check-box-row {
    display: flex;
    justify-content: center;
    align-items: center;
    @include r('xl') {
        left: 0;
    }
}
.check-box-row-text {
    margin-left: 10px;
    font-size: 12px;
    line-height: 1.25;
    letter-spacing: 0.03em;
    color: #262628;
}
.rc-checkbox {
    width: 37px;
    height: 37px;
    @include r('xl') {
        width: 30px;
        height: 30px;
    }
}
.rc-checkbox-input{
    width: 25px;
    height: 25px;
    @include r('xl') {
        width: 20px;
        height: 20px;
    }
}
.rc-checkbox-inner {
    width: 25px;
    height: 25px;
    @include r('xl') {
        width: 20px;
        height: 20px;
    }
}
.rc-checkbox-checked .rc-checkbox-inner:after {
    left: 8px;
    top: 4px;
    width: 6px;
    height: 11px;
    @include r('xl') {
        left: 7px;
        top: 4px;
        width: 4px;
        height: 8px;
    }
}
.react-confirm-alert-overlay{
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000000;
}
.editor-overlay-text{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 40px;
    text-align: center;
}
