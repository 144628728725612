* {
    line-height: 1.2;
    margin: 0;
}
.not-found {
    padding-top: 300px;
    text-align: center;
}
.not-found h1 {
    margin-bottom: 0.5em;
    color: #555;
    font-size: 2em;
    font-weight: 400;
}

.not-found  p {
    margin: 0 auto;
    /* width: 280px; */
}

@media only screen and (max-width: 280px) {

    .not-found p {
        width: 95%;
    }

    .not-found  h1 {
        font-size: 1.5em;
        margin: 0 0 0.3em;
    }
}