@import '../../vars-mixins';

.options {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 30px;
  border-bottom: 1px solid #DDDEE0;
  @include r('xl') {
    padding: 10px 15px;
    font-size: 10px;
  }

  @include r('md') {
    position: static;
    padding: 10px 45px;
  }

  @include r('sm') {
    padding: 5px 15px;
  }
}
.options-container {
  position: relative;
  background-color: #fff;
  padding-bottom: 30px;
  min-height: 345px;
  //overflow-y: scroll;
  @include r('md') {
    padding-bottom: 45px;
  }
  @include r('sm') {
    padding-bottom: 50px;
  }
}
.options button{
   width: calc(33.33% - 10px);
   display: inline-block;
   box-sizing: border-box;
   padding: 20px;
   background: #F3F4F6;
   border-radius: 12px;
  color: #262628;
   transition: 0.3s all ease-in-out;

   @include r('xl') {
     padding: 20px;
     font-size: 12px;
   }

   @include r('sm') {
    height: 42px;
    padding: 8px 10px;
    border-radius: 8px;
    font-size: 10px;
  }
}
.options button:not(:first-child) {
  margin-left: 10px;
}
.options button.active,
.options button:hover{
    background: #262628;
    color: #fff;
}
.option-hide{
    display: none;
}

.option-show {
  background: #FFFFFF;
  @include r('md') {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 710px; //minus height of price and options
    overflow-y: scroll;
  }
  @include r('sm') {
    height: 535px; //minus height of price and options
  }
}
