body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:after{
  content: ''!important;
}
.swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:after {
  content: 'prev'!important;
}