@import '../../vars-mixins.scss';

.image_controls{
    background: #FFFFFF;
    border-bottom: 1px solid #DDDEE0;
    box-sizing: border-box;
    border-radius: 16px;
    padding: 15px 30px;

    @include r('xl') {
        padding: 15px;
    }
    @include r('md') {
        padding: 70px 45px 15px;
    }
    @include r('sm') {
        padding: 70px 15px 15px;
    }
}
.image_controls_close-box{
    display: none;
    @include r('sm') {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 10px;
    }
}
.image_control_top{
    background: #FFFFFF;
    border: 1px solid #DDDEE0;
    box-sizing: border-box;
    border-radius: 16px;
    overflow: hidden;
    padding: 10px 0;

    @include r('xxl') {
        padding: 5px 0;
        border-radius: 12px;
    }
}
.image_control_top .row{
    margin: 0;
}
.image_control_top .col-6:first-child{
    border-right: 1px solid #DDDEE0;
}
.image_control_top .col-3{
    padding: 0;
}
.image_control_top button{
    border: 0;
    background: #FFFFFF;
    text-align: center;
    padding: 5px;
    height: 35px;
    width: 100%;
    outline: none;

    @include r('xxl') {
        height: 30px;
    }
}
.image_control_top .row .col-3:last-child button{
    border: 0;
}
.image_control_top button svg{
    display: inline-block;
    height: 100%;
    width: 100%;
    object-fit: contain;
}
.sliders{
    padding: 15px 0 0;
}
.sliders > .row {
    @include r('sm') {
        flex-flow: column;
    }
}
.sliders > .row .col-6 {
    @include r('sm') {
        flex: 0 0 100%;
        max-width: 100%;
    }
}
.sliders > .row .col-6:first-child{
    @include r('sm') {
        margin-bottom: 10px;
    }
}
.slider_box{
    background: #FFFFFF;
    border: 1px solid #DDDEE0;
    box-sizing: border-box;
    border-radius: 16px;
    padding: 20px;

    @include r('xxl') {
        border-radius: 12px;
        padding: 15px;
    }
    @include r('sm') {
        padding: 15px 10px;
    }
}

.slider_box label{
    display: block;
    margin: 0 0 20px 0;
    font-size: 16px;
    line-height: 1.25;

    @include r('xxl') {
        font-size: 12px;
    }
    @include r('sm') {
        margin-bottom: 25px;
        font-size: 10px;
    }
}
.slider_box .row {
    margin: 0;
    align-items: flex-end;
    @include r('sm') {
        justify-content: space-between;
    }
}
.rangeslider .rangeslider__handle{
    background: #262628;
    width: 26px;
    height: 26px;
    border: 0;
    @include r('xxl') {
        width: 20px;
        height: 20px;
    }
}
.rangeslider-horizontal .rangeslider__handle:after{
    display: none;
}
.rangeslider-horizontal .rangeslider__fill{
    background: #262628;
}
.rangeslider{
    box-shadow: none;
    background: #F3F4F6;
    margin: 10px 0;
    @include r('xxl') {
        margin: 15px 0;
    }
}
.rangeslider-horizontal{
    height: 8px;
    @include r('xxl') {
        height: 6px;
    }
}
.slider_box .col-3{
    display: flex;
    justify-content: flex-end;
    padding: 0;
    @include r('sm') {
        flex: 0;
    }
}
.slider_box .col-9{
    padding: 0;
    @include r('sm') {
        flex: 0 0 100%;
        max-width: calc(100% - 45px);
    }
}
.revert-button{
    background: #F3F4F6;
    border: 1px solid #F3F4F6;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 12px 12px;

    @include r('xxl') {
        padding: 8px 8px;
    }
}
.revert-button svg {
    @include r('xxl') {
        width: 18px;
        height: 18px;
    }
}
.arrow-container{
    @include r('sm') {
        background: #FFFFFF;
    }
}
.arrow-button{
    width: 100px;
    background: #FFFFFF;
    border: 0;
    border-bottom: 1px solid #DDDEE0;
    border-left: 1px solid #DDDEE0;
    border-right: 1px solid #DDDEE0;
    box-sizing: border-box;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    margin: 0 auto;
    display: block;
}
.add_image_box{
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 20px 30px;
    @include r('md') {
        padding: 15px 30px 20px;
    }
    @include r('sm') {
        padding: 85px 30px 0;
    }
    .object-buttons {
        @include r('sm') {
            height: 55px;
        }
    }
}
.template_color{
    padding: 0 30px;
    background-color: #fff;

    @include r('xl') {
        padding: 0 15px;
    }
    @include r('sm') {
        padding: 20px 15px 20px;
    }
    &__title {
        margin: 20px 20px 5px;
        font-size: 14px;
        text-align: center;
        @include r('xxl') {
            font-size: 12px;
        }
    }

    &__tip {
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        @include r('xxl') {
            font-size: 12px;
        }
    }
    .sketch-picker {
        margin: 0 auto;
    }
}

.d-popup {
    position: relative;
    width: 37%;
    margin: 0 auto;
    padding: 125px 155px 115px;
    border-radius: 16px;
    background: #fff;

    @include r('xl') {
        padding: 125px 75px 115px;
    }
    @include r('lg') {
        width: 75%;
        padding: 125px 45px 115px;
    }
    @include r('sm') {
        width: auto;
        margin: 0 15px;
        padding: 100px 25px;
    }
}

.d-input {
    &__file {
        opacity: 0;
        visibility: hidden;
        position: absolute;
    };
    &__file-button {
        display: block;
        width: 294px;
        margin: 0 auto;
        padding: 22px 0;
        background: #262628;
        border-radius: 16px;
        color: #fff;
        cursor: pointer;
        transition: 0.3s all ease-in;
    }
    &__file-button:hover {
        background: #FFA400;
    }
    &__file-button-text {
        display: block;
        text-align: center;
    }
}
