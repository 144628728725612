@import './../vars-mixins';

.swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:after{
  content: ''!important;
}
.swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:after {
  content: ''!important;
}
.gallery-thumbs .swiper-slide {
    border-radius: 12px;
    overflow: hidden;
}
.gallery__thumbs-img-box {
    padding-bottom: 73%;
}
.gallery__swiper .swiper-button-next {
    border-radius: 0;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    @include r('lg') {
        right: -1px;
        height: 105%;
        width: 40px;
        border-radius: 0;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
    }
    @include r('sm') {
        width: 20px;
    }
}
.gallery__swiper .swiper-button-prev {
    border-radius: 0;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    @include r('lg') {
        left: -1px;
        height: 105%;
        width: 40px;
        border-radius: 0;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    }
    @include r('sm') {
        width: 20px;
    }
}
.css-2b097c-container{
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
}
.css-1okebmr-indicatorSeparator {
    display: none!important;
}
.css-1hb7zxy-IndicatorsContainer {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    cursor: pointer;
}
.css-1wy0on6 {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    cursor: pointer;
}
.css-yk16xz-control{
    display: block!important;
    height: 50px;
    border: 1px solid #DDDEE0;
    border-radius: 12px!important;
    font-size: 16px;
    background: transparent!important;
    @include r('lg') {
        height: 40px;
    }
}
.css-1pahdxg-control{
  height: 50px !important;
  padding: 0 !important;
  border-radius: 12px!important;
  border-color: #DDDEE0 !important;
  font-size: 16px;
  box-shadow: none !important;
    @include r('xl') {
        height: 40px!important;
    }
}
.css-1pahdxg-control:hover {
    padding: 0 !important;
    border-color: #DDDEE0 !important;
    box-shadow: none !important;
}
.css-g1d714-ValueContainer,
.css-1hwfws3 {
    display: block!important;
    text-align: center;
    height: 100%;
    padding: 0 !important;
    cursor: pointer;
}
.css-1uccc91-singleValue{
    max-width: 100%!important;
    width: 100%!important;
    margin: 0!important;
    padding-left: 23px;
    text-align: left;
    @include r('xxl') {
      font-size: 14px !important;
    }
    @include r('lg') {
        padding-left: 10px;
    }
    @include r('sm') {
        font-size: 12px !important;
    }
}
.css-b8ldur-Input {
    height: 50px !important;
    margin: 0 !important;
    padding: 0 !important;
}
.css-b8ldur-Input input {
    height: 50px !important;
    @include r('xxl') {
      height: 40px !important;
    }
}
.form_error{
    color: red;
    padding: 10px 0 0 0;
}

.editor{
    width: 100%;
}
.editor iframe{
    width: 100%;
    height: 1200px;
}
/*** Ideas ***/
.tabs {
    @include r('lg') {
        padding-bottom: 20px;
    }
}

.tabs__item{
    position: relative;
}
.tabs__item button{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 111;
    border: 0;
    background: transparent;
}

.gallery-thumbs{
    padding: 0;

    @include r('sm') {
        padding: 0 28px;
    }
}

.gallery__thumbs {
    @include r('lg') {
        padding: 0;
    }
}
