@import '../vars-mixins';

.delivery {
  padding-bottom: 0;
}
.contacts__bottom:after {
  height: 100%;
  @include r('lg') {
    height: 1px;
  }
}