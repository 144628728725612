@import './vars-mixins';

.header{
  background: #FFFFFF;
  z-index: 110;
}

.header.in {
  position: fixed;
  transition: all .5s cubic-bezier(.05,.66,.56,.74);
  -webkit-box-shadow: 1px 6px 34px -16px rgba(0,0,0,0.47);
  box-shadow: 1px 6px 34px -16px rgba(0,0,0,0.47);
  animation: 0.5s ease-in-out fadeheader;
  @keyframes fadeheader {
    from {
      opacity: 0.05
    }
    to {
      opacity: 1
    }
  }
}
.header__logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 100px;
  @include r('sm') {
    width: 150px;
  }
}
.header__logo img,
.header__logo svg {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.language .css-yk16xz-control {
  border: none;
}

.language .css-1pahdxg-control {
  border: none!important;
  background: #F3F4F6;
}

a:hover {
  text-decoration: none;
  color: initial;
}

.burger-menu__nav-box {
  @include r('lg') {
    z-index: -1;
  }
}
