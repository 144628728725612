@import '../../vars-mixins.scss';

.product_controls{
    padding: 15px 30px;
    @include r('xl') {
        padding: 10px 15px;
    }
    @include r('md') {
        padding: 10px 45px;
    }
    @include r('sm') {
        padding: 10px 15px;
    }
    button.close {
        @include r('md') {
            display: none;
        }
    }
}
.product-list {
    padding-bottom: 70px;
    @include r('md') {
        padding-top: 52px;
        padding-bottom: 0;
    }
}
.product_item{
    background: #FFFFFF;
    border: 1px solid #DDDEE0;
    box-sizing: border-box;
    border-radius: 16px;
    padding: 20px 30px;
    margin: 0 30px 15px;
    position: relative;
    transition: 0.3s all ease-in-out;

    @include r('xl') {
        padding: 15px;
        margin: 0 15px 15px;
    }
    @include r('md') {
        margin: 0 45px 15px;
    }
    @include r('sm') {
        padding: 10px;
        border-radius: 12px;
        margin: 0 15px 10px;
    }
}
.product_item:last-child{
    margin-bottom: 50px;
}
.product_item:hover{
    box-shadow: 0 8px 25px rgba(17, 17, 17, 0.15);
}
.info_wrapper{
    position: absolute;
    top: 25px;
    right: 30px;
    z-index: 10;
    @include r('xl') {
        top: 15px;
        right: 15px;
    }
    @include r('sm') {
        top: 10px;
        right: 10px;
    }

    svg {
        @include r('xl') {
            width: 30px;
            height: 30px;
        }
    }
}
.product_description{
    position: absolute;
    right: -10px;
    top: -10px;
    width: 405px;
    min-height: 275px;
    padding: 55px 35px 35px 35px;
    background: #FFFFFF;
    box-shadow: 0px 8px 25px rgba(17, 17, 17, 0.15);
    border-radius: 16px;
    z-index: 10;

    @include r('xl') {
        width: 300px;
        min-height: 225px;
        padding: 45px 20px 20px 20px;
    }

    p {
        margin-bottom: 10px;
        font-size: 14px;
        line-height: 1.25;
        @include r('xl') {
            font-size: 12px;
        }
    }
}
.product_description-close{
    position: absolute;
    top: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
    // background: url('../../assets/info_orange-close.svg') 50% 50%;
    background-size: contain;

    @include r('xl') {
        width: 30px;
        height: 30px;
    }
}
// .info_wrapper:hover .product_description{
//     display: block;
// }
.product-img-box {
    padding: 0;
}
.product_item .img{
    padding: 10px;
    border: 1px solid #DDDEE0;
    border-radius: 16px;
    position: relative;

    @include r('md') {
        padding: 5px;
        border-radius: 12px;
    }
}
.product_item .img button{
    border: 0;
    background: transparent;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
}
.product_item img{
    width: 100%;
    height: 145px;
    object-fit: contain;

    @include r('xl') {
        height: 100px;
    }
    @include r('sm') {
        height: 60px;
    }
}
.product-title{
    display: block;
    padding-top: 10px;
    font-size: 14px;
    line-height: 1.25;
    text-align: center;

    @include r('xl') {
        font-size: 10px;
    }

    @include r('sm') {
        padding-top: 5px;
    }
}
.product-text-box {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    padding-left: 50px;
    padding-top: 15px;
    @include r('xl') {
        padding-left: 30px;
        padding-right: 0;
    }
    @include r('lg') {
        padding-left: 20px;
    }
    @include r('sm') {
        padding-top: 0;
        padding-left: 20px;
    }
}
.product-type {
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 24px;
    line-height: 21px;
    color: #262628;
    @include r('xl') {
        margin-bottom: 10px;
        font-size: 18px;
    }
    @include r('sm') {
        margin-bottom: 5px;
        padding-right: 35px;
        font-size: 16px;
    }
}
.product-color-tip{
    font-size: 12px;
    margin-bottom: 10px;
    display: block;
    color: #99A0A3;

    @include r('xl') {
        font-size: 10px;
    }
    @include r('lg') {
        padding-right: 35px;
    }
    @include r('sm') {
        margin-bottom: 8px;
    }
}
.colors{
    list-style: none;
    padding: 0;
    margin-bottom: 15px;
    @include r('sm') {
        margin-bottom: 10px;
    }
}
.colors li{
    display: inline-block;
    padding-right: 10px;
}
.colors button{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 3px solid #F3F4F6;
    @include r('xl') {
        width: 25px;
        height: 25px;
    }
}
.category_list{
    list-style: none;
    padding: 10px 20px 30px;

    @include r('xl') {
        padding: 5px 10px 30px;
    }
    @include r('md') {
        padding: 20px 40px 30px;
    }
    @include r('sm') {
        padding: 8px 7.5px 8px;
    }
}
.category_list li{
    width: 33.3%;
    display: inline-block;
    padding: 10px;

    @include r('xl') {
        padding: 5px;
    }

    @include r('sm') {
        width: 50%;
        padding: 3px 7.5px;
    }
}
.category_img{
    height: 0;
    padding-bottom: 63%;
    margin: 15px;

    @include r('xl') {
        margin: 10px;
    }
}
.category_list li img{
    width: 100%;
}
.category_item{
    /* height: 220px; */
    position: relative;
    border: 1px solid #DDDEE0;
    border-radius: 16px;
    transition: 0.3s all;
}
.category_item:hover {
    box-shadow: 0 8px 25px rgba(17, 17, 17, 0.15);
}
.category_item h6{
    margin: 10px;
    text-align: center;
    font-size: 14px;
    line-height: 1.25;

    @include r('xl') {
        font-size: 12px;
    }
}
.category_item button{
    border: 0;
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    font-size: 14px;

    @include r('xl') {
        font-size: 12px;
    }
}
.price_box{
    background: #FFFFFF;
    border: 1px solid #DDDEE0;
    box-sizing: border-box;
    border-radius: 16px;
    padding: 14px;
    margin: 18px 0 0 0;

    @include r('xl') {
        padding: 15px 20px;
        border-radius: 12px;
        margin-top: 10px;
    }

    @include r('sm') {
        padding: 10px 15px;
        margin-top: 0;
        font-size: 12px;
    }
}
.price_box p{
    margin: 0;
    padding: 0;

    @include r('xl') {
       font-size: 12px;
    }
}
.price_box .row {
    align-items: center;
}
.price-amount {
    display: flex;
    justify-content: flex-end;
}
.select_product{
    background: #262628;
    border-radius: 16px;
    border: 0;
    color: #FFFFFF;
    display: block;
    width: 100%;
    padding: 15px;
    margin-top: 20px;
    font-size: 14px;
    transition: 0.3s all ease-in-out;
    @include r('xl') {
        border-radius: 12px;
        margin-top: 15px;
        font-size: 10px;
    }

    @include r('sm') {
        margin-top: 10px;
    }
}
.select_product:hover {
    background: #FFA400;
}
.back_btn{
    display: flex;
    align-items: center;
    background: #F3F4F6;
    border-radius: 16px;
    padding: 15px 25px;
    border: 0;
    font-size: 14px;
    line-height: 1;
    color: #262628;
    transition: 0.3s all ease-in-out;

    @include r('xl') {
        padding: 10px 15px;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: -0.02em;
    }

    @include r('sm') {
        padding: 9px 10px;
        border-radius: 8px;
    }

    svg {
        margin-right: 10px;

        @include r('xl') {
            width: 18px;
        }

        @include r('sm') {
            height: 22px;
        }
    }
    svg * {
        transition: 0.3s all ease-in-out;
    }
}
.back_btn:hover{
    background: #262628;
    color: #fff;
}
.back_btn:hover svg *{
    stroke: #fff;
}
.size-number-box {
    padding: 0;
    @include r('sm') {
        flex: 0 0 50%;
        max-width: 50%;
        padding-right: 0;
    }
}
.size-number-box .form-control {
    height: 50px;
    border: 1px solid #DDDEE0;
    border-radius: 12px;
    overflow: hidden;
    @include r('xl') {
        height: 40px;
    }
}
.size-number-box .react-numeric-input {
    position: relative;
    box-sizing: border-box;
    border-radius: 12px;
    overflow: hidden;
}
.size-number-box .react-numeric-input b {
    top: 50% !important;
    width: 32px !important;
    height: 32px !important;
    transform: translateY(-50%);
    border-radius: 50% !important;
    background: #F3F4F6 !important;
    border: none !important;
    cursor: pointer!important;
    @include r('xl') {
        width: 25px !important;
        height: 25px !important;
    }
}
.size-number-box .react-numeric-input b:nth-child(2) {
    right: 10px !important;
}
.size-number-box .react-numeric-input b:last-child {
    left: 10px !important;
}
.css-yk16xz-control {
    border-color: #DDDEE0 !important;
}
.size-box {
    @include r('sm') {
        flex: 0 0 33%;
        max-width: 33%;
        padding: 0 5px;
    }
}
.size-box .css-yk16xz-control {
    @include r('xl') {
        height: 40px;
    }
}
.size-box .css-1uccc91-singleValue {
    @include r('sm') {
        padding-left: 6px;
    }
}
.size-box > div > div:first-of-type > div:last-child {
    @include r('sm') {
        right: 0;
    }
}
.size_item{
    padding: 0 0 10px 0;
}
.sizes_list{
    padding: 0 0 10px 0;
    @include m('sm') {
        padding: 0;
    }
}
.add_size{
    background: #F3F4F6;
    border-radius: 12px;
    padding: 10px 15px;
    border: 0;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    color: #262628;

    @include r('xl') {
        font-size: 12px;
    }
}
.add_size svg {
    margin-right: 10px;

    @include r('xl') {
        width: 20px;
    }
}
.product_item .button_clickable{
    margin-top: 0;
    border-color: #DDDEE0;
}

.react-confirm-alert {
    width: 100%;
}

.react-confirm-alert-body {
    width: 49.4% !important;
    margin: 0 auto;
    padding: 6.3% !important;
    font-family: 'Inter' !important;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: 0.75px;color: #14142B;
    text-align: center !important;
}

.react-confirm-alert-body h1 {
    margin-bottom: 30px;
    font-weight: 600;
    font-size: 48px;
    line-height: 120%;
    letter-spacing: 1px;
    color: #262628;
}

.react-confirm-alert-button-group {
    justify-content: center !important;
    margin-top: 60px !important;
}

.react-confirm-alert-button-group > button {
    width: 41.6%;
    height: 60px;
    margin-right: 0 !important;
    border-radius: 16px !important;
    font-weight: 500;
    font-size: 18px !important;
    line-height: 21px;
    letter-spacing: -0.02em;
}

.react-confirm-alert-button-group > button:not(:last-child) {
    margin-right: 30px !important;
}
