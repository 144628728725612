@import '../vars-mixins';

.news__title {
  @include r('lg') {
      margin-bottom: 100px;
      text-align: center;
  }
}

.news__search {
  position: absolute;
  right: 30px;
  top: 100px;
  @include m('xxl') {
    top: 130px;
  }
  @include r('lg') {
    top: 185px;
    right: 50%;
    transform: translateX(50%);
  }
  @include r('sm') {
    top: 160px;
  }
}

.news__search-input{
  margin-bottom: 0;
  height: 50px;
  width: 250px;
}
.search_icon{
  position: absolute;
  top: 10px;
  right: 10px;
}