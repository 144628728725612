@import '../../vars-mixins.scss';

.add-text-box {
    display: flex;
    justify-content: center;
    padding: 20px;
    @include r('lg') {
        padding: 20px;
    }
    @include r('md') {
        padding: 15px 20px 20px;
    }
    @include r('sm') {
        height: 380px;
        padding: 85px 30px 0;
    }
    .object-buttons{
        width: auto;
        @include r('xl') {
            height: 105px;
        }
        @include r('lg') {
            width: 150px;
        }
        @include r('sm') {
            height: 55px;
        }
    }
}
.text_tools{
    padding: 15px 30px;
    background: #FFFFFF;
    border-bottom: 1px solid #DDDEE0;
    box-sizing: border-box;
    border-radius: 16px;
    @include r('xl') {
        padding: 15px;
    }
    @include r('lg') {
        padding: 15px 15px 5px 15px;
    }
    @include r('md') {
        padding: 70px 45px 5px;
    }
    @include r('sm') {
        padding: 70px 15px 15px;
    }
}
.text_control_top {
    background: #FFFFFF;
    border: 1px solid #DDDEE0;
    box-sizing: border-box;
    border-radius: 16px;
    overflow: hidden;
    padding: 10px 0;

    @include r('xxl') {
        padding: 5px 0;
        border-radius: 12px;
    }
}
.text_control_body{
    padding: 10px 10px 0;
}
.text_tools textarea{
    border: 1px solid #DDDEE0;
    box-sizing: border-box;
    border-radius: 16px;
    width: 100%;
    padding: 10px 23px;
    height: 112px;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.2px;

    @include r('sm') {
        height: 92px;
        font-size: 12px;
    }
}
.text_tools input{
    border: 1px solid #DDDEE0;
    box-sizing: border-box;
    border-radius: 12px;
    width: 100%;
    padding: 16px 23px;

    @include r('sm') {
        font-size: 12px;
    }
}
.open_color{
    background: #F3F4F6;
    border-radius: 12px;
    border: 0;
    width: 51px;
    height: 51px;
    float: right;
    @include r('sm') {
        width: 48px;
        height: 48px;
    }
}
.open_color svg{
    @include r('sm') {
        width: 19px;
    }
}
.color_picker .row{
    margin: 0 -5px;

    @include r('lg') {
        justify-content: space-between;
    }
}
.color_picker .col-9, .color_picker .col-3{
    padding: 0 5px;
}
.color_picker .col-9{
    @include r('lg') {
        flex: 0 0 calc(100% - 61px);
        max-width: calc(100% - 51px);
    }
}
.color_picker .col-3{
    @include r('lg') {
        flex: 0 0 51px;
        max-width: 51px;
    }
}
.color_picker input{
    @include r('sm') {
        padding: 16px 10px;
    }
}
.text_tools .col-6{
    padding: 0 5px;
}
.text_tools .col-6:last-child button{
    border-right: 0;
}
.inputs{
    padding-bottom: 10px;

    .css-yk16xz-control{
        @include r('lg') {
            height: 50px;
        }
        @include r('sm') {
            height: 40px;
        }
    }
}
.textarea_wrapper{
    padding-bottom: 5px;
}
.picker_wrapper{
    padding: 20px 0;
}
.text_align{
    margin-top: 10px;
}
.image_control_top .col-4{
    border-right: 1px solid #DDDEE0;
}
.image_control_top .col-4:last-child{
    border-right: 0
}
.text_align button svg{
    width: 30px!important;
}
