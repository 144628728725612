@import '../../vars-mixins';

.objects-container{
    position: relative;
    // z-index: 20;
    background-color: #fff;
    @include r('lg') {
        min-height: 119px;
    }
    @include r('md') {
        min-height: 366px;
    }
    @include r('sm') {
        height: auto;
        min-height: 180px;
    }
}

.objects-list{
    padding: 10px 30px;
    @include r('xl') {
        padding: 10px 15px;
    }
    @include r('lg') {
        padding: 10px 15px;
    }
    @include r('md') {
        padding: 10px 45px;
    }
    @include r('sm') {
        padding: 10px 15px 10px;
    }
}
.object-item{
    display: flex;
    justify-content: space-between;
    list-style: none;
    background: #FFFFFF;
    border: 1px solid #DDDEE0;
    box-sizing: border-box;
    border-radius: 16px;
    padding: 10px;
    margin-bottom: 10px;
    line-height: 0;
    transition: 0.3s all ease-in;
    cursor: pointer;
}
.object-item:hover{
    box-shadow: rgb(17 17 17 / 20%) 4px 8px 12px;
}
.object-item .row{
    justify-content: space-between;
    flex-shrink: 1;
    @include r('sm') {
        justify-content: flex-end;
    }
}
.object-item .row:first-child{
    @include r('sm') {
        width: 43%;
    }
}
.object-item .row:last-child{
    @include r('sm') {
        width: 57%;
        margin-right: 0;
    }
}
.object-item .row:first-child .col-6:first-child{
    @include r('sm') {
        flex: 0 0 70px;
        max-width: 70px;
        padding-right: 0;
    }
}
.object-item .row:first-child .col-6:last-child{
    @include r('sm') {
        flex: 0 0 calc(100% - 70px);
        max-width: calc(100% - 70px);
        padding: 0;
    }
}
.object-item .row:last-child .col-3{
    @include r('sm') {
        display: flex;
        align-items: center;
        flex: 0 0 38px;
        max-width: 38px;
        padding: 0;
    }
}
.object-item .row:last-child .col-3:not(:last-child){
    @include r('sm') {
        margin-right: 5px;
    }
}
.object-item .button_clickable{
    @include r('lg') {
        margin-top: 10px;
    }
    @include r('sm') {
        margin-top: 0;
    }
}
.object-item-text{
    position: relative;
    width: 80px;
    height: 80px;
    border: 1px solid #DDDEE0;
    box-sizing: border-box;
    border-radius: 16px;
    font-size: 50px;
    font-weight: bold;
    text-align: center;
    line-height: 80px;
    @include r('xxl') {
        width: 60px;
        height: 60px;
        font-size: 30px;
        line-height: 60px;
    }
    @include r('sm') {
        width: 45px;
        height: 45px;
        margin-right: 0;
        font-size: 20px;
        line-height: 46px;
    }
}
.select_object{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    background: transparent;
    border: 0;
}
.object-image{
    width: 80px;
    height: 80px;
    padding: 10px;
    border: 1px solid #DDDEE0;
    box-sizing: border-box;
    border-radius: 16px;
    line-height: 80px;
    overflow: hidden;
    @include r('xxl') {
        width: 60px;
        height: 60px;
        font-size: 30px;
        line-height: 60px;
    }
}
.object-image img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.object-item h6{
    padding-left: 15px;
    @include r('xxl') {
        padding-left: 0;
    }
}
.button_clickable button{
    background: transparent;
    border: 0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 1;
}
.button_clickable{
    position: relative;
    background: #FFFFFF;
    border: 1px solid #F3F4F6;
    box-sizing: border-box;
    border-radius: 12px;
    width: 50px;
    height: 50px;
    margin-top: 15px;
    @include r('xl') {
        width: 40px;
        height: 40px;
    }
}
.button_clickable svg{
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
