@import './vars-mixins';

//Pop ups
.popup-content {
  width: 100%;
  padding: 0;
  border: none;
  background: transparent!important;
  @include r('xxl') {
    border-radius: 12px;
  }
}

.b-popup {
  position: relative;
  width: auto;
  padding: 85px 250px;
  background: #fff;
  border-radius: 16px;
  @include r('xxl') {
    padding: 50px 100px;
  }
  &_img {
    width: 50%;
    margin: 0 auto;
    padding: 6.6%;
    @include r('lg') {
      width: 66%;
      padding-top: 60px;
    }
    @include r('sm') {
      width: calc(100% - 30px);
    }
  }
  &_order {
    margin: 0 auto;
    @include r('sm') {
      padding: 60px 15px 30px;
    }
  }
  &__title {
    margin-bottom: 30px;
    font-weight: 600;
    font-size: 48px;
    line-height: 1.2;
    text-align: center;
    letter-spacing: 1px;
    color: #262628;
    @include r('xxl') {
      font-size: 36px;
    }
    @include r('sm') {
      margin-bottom: 10px;
      font-size: 24px;
    }
  }
  &__text {
    margin-bottom: 0;
    font-size: 18px;
    line-height: 1.5;
    text-align: center;
    letter-spacing: 0.75px;
    color: #14142B;
    @include r('xxl') {
      font-size: 14px;
    }
    @include r('sm') {
      font-size: 12px;
    }
  }
  &__link {
    display: inline-block;
    margin-top: 1rem;
    color: #1264D1;
    text-decoration: underline;
    transition: 0.3s all;

    &:hover {
      color: #1264D1;
      text-decoration: none;
    }
  }
  &__btns {
    display: flex;
    justify-content: center;
    margin-top: 60px;
    @include r('xxl') {
      margin-top: 40px;
    }
    @include r('sm') {
      margin-top: 30px;
    }
  }
  &__btn {
    width: 30%;
    height: 60px;
    border-radius: 16px;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.17;
    letter-spacing: -0.02em;
    color: #262628;
    background: #F3F4F6;
    transition: 0.3s all ease-in-out;
    @include r('xxl') {
      height: 40px;
      border-radius: 12px;
      font-size: 14px;
    }
    &:hover {
      color: #FFFFFF;
      background: #262628;
    }
  }
  &__btn:first-child {
    margin-right: 30px;
    color: #FFFFFF;
    background: #262628;
    @include r('xxl') {
      margin-right: 20px;
    }
    &:hover {
      color: #262628;
      background: #F3F4F6;
    }
  }
}

.inner-popup {
  position: relative;
  width: 1430px;
  margin: 0 auto;

  @include r('xxl') {
    width: 930px;
  }
  @include r('sm') {
    width: calc(100% - 30px);
  }
}

.close {
  position: absolute;
  top: 35px;
  right: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border: 1px solid #aaa !important;
  box-sizing: border-box;
  border-radius: 12px;
  font-size: 0;
  background: 50% 50% no-repeat#F3F4F6 !important;
  cursor: pointer;
  outline: none;
  transition: 0.3s all;
  z-index: 1;
  @include r('xxl') {
    top: 20px;
    right: 20px;
  }
  @include r('sm') {
    top: 15px;
    right: 15px;
    width: 40px;
    height: 40px;
  }

  svg{
    @include r('sm') {
      width: 15px;
      height: 14px;
    }
  }
}

.close-btn {
  display: none;
  @include r('md') {
    display: flex;
    width: 40px;
    height: 40px;
    top: 10px;
    right: 15px;
    transition: 0.3s all ease-in;
    svg {
      width: 14px;
      height: 14px;
      transition: 0.3s all ease-in;
    }
  }
}

.close-btn:hover {
  background: #262628!important;
  svg path {
    stroke: #FFFFFF;
  }
}

.show-btn {
  display: none;
  @include r('md') {
    position: absolute;
    top: 15px;
    right: 45px;
    align-items: center;
    width: auto;
    color: #262628;
    transition: 0.3s all ease-in;
    z-index: 50;
  }

  @include r('sm') {
    right: 15px;
  }
  &_box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background: #F3F4F6;
    border: 1px solid #F3F4F6;
    box-sizing: border-box;
    border-radius: 8px;
    & svg{
      transform: rotate(180deg);
    }
  }
  & span{
    margin-right: 10px;
    font-size: 12px;
  }
}

.to-editor-btn{
  @include r('md') {
    position: initial;
  }
}

//Forms
.form_group {
  position: relative;
    padding-bottom: 40px;
    @include r('xxl') {
      padding-bottom: 25px;
    }
}

.form-box:first-child {
  margin-right: 60px;
  @include r('sm') {
    margin-right: 10px;
  }
}

.b-form {
  margin-top: 50px;
  &__columns {
    display: flex;
    justify-content: space-between;
  }
  label {
    display: block;
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 1.2;
    @include r('xxl') {
      margin-bottom: 10px;
      font-size: 14px;
    }
  }
  input {
    display: block;
    width: 430px;
    height: 70px;
    padding: 14px 18px;
    border: 1px solid #DDDEE0;
    border-radius: 16px;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 1.57;
    letter-spacing: 0.3px;
    @include r('xxl') {
      width: 330px;
      height: 50px;
      border-radius: 12px;
    }
    @include r('sm') {
      width: 100%;
      padding: 14px 10px;
    }
  }
  .b-form__input-wide {
    width: 100%;
  }
  &__btn-box {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    @include r('xxl') {
      margin-top: 30px;
    }
    @include r('sm') {
      margin-top: 10px;
    }
  }
}

.form-box {
  @include r('sm') {
    width: 48%;
  }
}

//Btns
.b-btn {
  width: 100%;
  // width: 360px;
  height: 90px;
  border: 0;
  border-radius: 16px;
  background: #FFA400;
  font-size: 18px;
  letter-spacing: 0.2px;
  color: #FFFFFF;
  transition: 0.3s all ease-in-out;

  @include r('xxxl') {
    // width: 300px;
    height: 70px;
    font-size: 16px;
  }

  @include r('xxl') {
    // width: 240px;
    font-size: 14px;
  }

  @include r('lg') {
    font-size: 12px;
  }

  @include r('md') {
    font-size: 14px;
  }

  @include r('sm') {
    height: 50px;
    border-radius: 8px;
    font-size: 12px;
  }

  svg {
    width: 24px;
    margin-left: 20px;

    @include r('xxl') {
      width: 18px;
      margin-left: 10px;
    }
    @include r('lg') {
      margin-left: 5px;
    }
  }
}
.b-btn:hover {
  background: #262628;
}
